import { Container, Title } from "./styles";

import { ModalFilterByOrganizer } from "components/ModalFilterByOrganizer";

export function HeaderTitleOrganizerProfile() {
  return (
    <Container>
      <Title>Perfil do organizador</Title>

      <ModalFilterByOrganizer type="change_organizer" />
    </Container>
  );
}

import { useState } from "react";

import { Radio, Select } from "antd";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { RiCloseCircleLine, RiPencilFill, RiUserLine } from "react-icons/ri";

import { authClient } from "services/authClient";
import { Purchase } from "types";

import Datas from "constants/Datas";
import { Colors } from "constants/colors";

import {
  ButtonNext,
  ButtonPrev,
  CardSelected,
  ContainerBorder,
  ContainerBorderAccountForm,
  ContainerBorderSelected,
  ContainerInputFull,
  ContainerModalReverse,
  FormAccount,
  FormFull,
  FormLabel,
  ModalStyled,
  StyledSelect,
} from "./styles";

import { InputFormAccount } from "components/Form/InputFormAccount";

import coin_icon from "assets/coin-icon.svg";
import coin_icon_active from "assets/coin-icon-active.svg";

const { Option } = Select;

interface AccountFormData {
  refund_type: number;
  holder_name: string;
  bank_code: string;
  routing_number: string;
  account_number: string;
  document: string;
  type_document: number;
  type_account: number;
  digit: string;
}

const documentFormSchema = yup.object().shape({
  refund_type: yup.number().notRequired(),
  holder_name: yup.string().required("Insira o nome do titular conta"),
  bank_code: yup.string().required(),
  routing_number: yup.string().required(),
  account_number: yup.string().required(),
  document: yup.string().required("Insira o número do documento"),
  type_document: yup.number().notRequired(),
  type_account: yup.number().required(),
  digit: yup.string().required(),
});

interface ModalConfirmReverseProps {
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
  purchase: Purchase | null;
  purchase_id: number;
  showModalConfirm(): void;
  showModalError(): void;
}

export function ModalConfirmReverse({
  isModalVisible,
  handleOk,
  handleCancel,
  purchase,
  purchase_id,
  showModalConfirm,
  showModalError,
}: ModalConfirmReverseProps) {
  const [step, setStep] = useState<number | null>(null);

  const [reverseSelected, setReverseSelected] = useState<string | null>(null);

  const [accountSelected, setAccountSelected] = useState(null);

  const [loadingByCredit, setLoadingByCredit] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AccountFormData>({
    resolver: yupResolver(documentFormSchema),
  });

  async function handleCancelPurchaseByCredit() {
    try {
      setLoadingByCredit(true);

      await authClient.patch(`/financial/cancel-purchase/${purchase_id}`);

      setLoadingByCredit(false);

      handleOk();

      showModalConfirm();
    } catch (err) {
      setLoadingByCredit(false);
      showModalError();
    }
  }

  const [loadingByBoleto, setLoadingByBoleto] = useState(false);

  const handleCancelPurchaseByBoleto: SubmitHandler<AccountFormData> = async ({
    ...data
  }) => {
    const {
      bank_code,
      holder_name,
      account_number,
      digit,
      document,
      routing_number,
      type_account,
      type_document,
    } = data;

    const account_information = {
      bank_code,
      holder_name,
      account_number,
      digit,
      document,
      routing_number,
      type_account,
      type_document,
    };

    try {
      setLoadingByBoleto(true);

      await authClient.patch(`/financial/cancel-purchase/${purchase_id}`, {
        type_account: 2,
        account_information,
      });

      setLoadingByBoleto(false);

      handleOk();

      showModalConfirm();
    } catch (err) {
      setLoadingByBoleto(false);
      showModalError();
    }
  };

  return (
    <ModalStyled
      width={900}
      visible={isModalVisible}
      onOk={handleOk}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      onCancel={() => {
        handleCancel();
        setReverseSelected(null);
        setStep(null);
        setAccountSelected(null);
      }}
      footer={null}
    >
      <ContainerModalReverse>
        {step === null &&
        purchase?.payment_type === "credit-card" &&
        purchase?.payment_status === "PAID" ? (
          <>
            <div className="header-texts">
              <h3>Estorno da compra boleto</h3>

              <p>Selecione a forma na qual você deseja receber o seu estorno</p>
            </div>

            <ContainerBorderSelected>
              <CardSelected
                active={reverseSelected === "account"}
                onClick={() => setReverseSelected("account")}
              >
                <div className="icon">
                  {reverseSelected === "account" ? (
                    <img
                      src={coin_icon_active}
                      width={32}
                      height={27}
                      alt="coin"
                    />
                  ) : (
                    <img src={coin_icon} width={32} height={27} alt="coin" />
                  )}
                </div>

                <div className="column">
                  <h5>Receber o valor na minha conta</h5>

                  <h6>
                    O valor da compra será devolvido em sua fatura em até 90
                    dias.
                  </h6>
                </div>
              </CardSelected>
            </ContainerBorderSelected>
            <div className="button-next">
              <ButtonNext
                onClick={handleCancelPurchaseByCredit}
                disabled={reverseSelected === null}
                active={!!reverseSelected}
              >
                <span>Confirmar</span>
              </ButtonNext>
            </div>
          </>
        ) : null}

        {step === null &&
        purchase?.payment_type === "boleto" &&
        purchase?.payment_status === "PAID" ? (
          <>
            <div className="header-texts">
              <h3>Estorno da compra boleto</h3>

              <p>Selecione a forma na qual você deseja receber o seu estorno</p>
            </div>

            <ContainerBorderSelected>
              <CardSelected
                active={reverseSelected === "account"}
                onClick={() => setReverseSelected("account")}
              >
                <div className="icon">
                  {reverseSelected === "account" ? (
                    <img
                      src={coin_icon_active}
                      width={32}
                      height={27}
                      alt="coin"
                    />
                  ) : (
                    <img src={coin_icon} width={32} height={27} alt="coin" />
                  )}
                </div>

                <div className="column">
                  <h5>Receber o valor na minha conta</h5>

                  <h6>
                    Informe os dados da sua conta bancária e receba o valor do
                    estorno em até 90 dias úteis.
                  </h6>
                </div>
              </CardSelected>
            </ContainerBorderSelected>
            <div className="button-next">
              <ButtonNext
                disabled={reverseSelected === null}
                active={!!reverseSelected}
                onClick={() => setStep(1)}
              >
                <span>Prosseguir</span>
              </ButtonNext>
            </div>
          </>
        ) : null}

        {step === 1 ? (
          <>
            <div className="header-texts">
              <h3>Estorno da compra</h3>

              <p>Selecione a forma na qual você deseja receber o seu estorno</p>
            </div>
            <ContainerBorder>
              <h5 className="title">
                Selecione a conta bancária para receber o estorno:
              </h5>

              <div className="container-select">
                <h6 className="title">Conta bancária</h6>

                <div className="select">
                  <StyledSelect
                    bordered={false}
                    labelInValue
                    placeholder="Selecione uma opção"
                    onChange={(e: any) => {
                      setAccountSelected(e.value);
                    }}
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      outline: 0,
                      border: `2px solid ${Colors.secondary20}`,
                      borderRadius: "8px",
                      marginTop: "2px",
                    }}
                  >
                    <Option value={"another"} key={"another"}>
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <RiPencilFill color={Colors.success100} />{" "}
                        <h4 style={{ marginBottom: 0, marginLeft: 10 }}>
                          Outra Conta
                        </h4>
                      </div>
                    </Option>
                  </StyledSelect>
                </div>
              </div>
            </ContainerBorder>

            <div className="container-buttons">
              <ButtonPrev onClick={() => setStep(null)}>
                <span>Voltar</span>
              </ButtonPrev>

              <ButtonNext
                active={accountSelected !== null}
                onClick={() => setStep(2)}
              >
                <span>Prosseguir</span>
              </ButtonNext>
            </div>
          </>
        ) : null}

        {step === 2 ? (
          <FormAccount onSubmit={handleSubmit(handleCancelPurchaseByBoleto)}>
            <div className="header-texts">
              <h3>Estorno da compra</h3>

              <p>
                Informa os dados bancários da conta na qual você deseja receber
                o seu estorno.
              </p>
            </div>
            <ContainerBorderAccountForm>
              <div className="header-title">
                <h5 className="title">Informações da conta</h5>
              </div>

              <div className="informations">
                <InputFormAccount
                  isFull
                  {...register("holder_name")}
                  icon={<RiUserLine size={18} color={Colors.secondary40} />}
                  type="text"
                  label="Nome do titular"
                  placeholder="Insira o nome do titular da conta..."
                  error={errors.holder_name}
                />
              </div>

              <div className="radios">
                <span className="label">Tipo de documento</span>

                <div className="radio">
                  <Radio.Group onChange={() => {}} value={""}>
                    <Radio value={1}>CPF</Radio>
                    <Radio value={2}>CNPJ</Radio>
                  </Radio.Group>
                </div>
              </div>

              <div className="informations">
                <InputFormAccount
                  isFull
                  {...register("document")}
                  icon={<RiUserLine size={18} color={Colors.secondary40} />}
                  type="text"
                  label="CPF"
                  placeholder="Insira o seu número de CPF..."
                  mask="cpf"
                  error={errors.document}
                />
              </div>

              <FormFull>
                <ContainerInputFull isEditing>
                  <FormLabel>
                    <h2>Banco</h2>
                  </FormLabel>

                  <div className="select">
                    <Controller
                      control={control}
                      name="bank_code"
                      rules={{ required: "Salutation is required" }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <StyledSelect
                          bordered={false}
                          labelInValue
                          placeholder="Selecione uma opção"
                          value={value ?? "Selecione uma opção"}
                          onChange={(e: any) => {
                            onChange(e.value);
                          }}
                          style={{
                            width: "100%",
                            minHeight: "100%",
                            outline: 0,
                            border: `2px solid ${Colors.secondary20}`,
                            borderRadius: "8px",
                            marginTop: "2px",
                          }}
                        >
                          {Datas.Banks.map((bank) => (
                            <Option value={bank.value} key={bank.value}>
                              {`${bank.value} - ${bank.label}`}
                            </Option>
                          ))}
                        </StyledSelect>
                      )}
                    />
                  </div>

                  {errors.bank_code ? (
                    <span className="error">Insira um banco válido</span>
                  ) : null}
                </ContainerInputFull>
              </FormFull>

              <div className="informations">
                <InputFormAccount
                  isFull
                  {...register("routing_number")}
                  icon={<RiUserLine size={18} color={Colors.secondary40} />}
                  type="text"
                  label="Agência (sem dígito)"
                  placeholder="Insira o número da agência..."
                  error={errors.routing_number}
                />
              </div>

              <div className="informations">
                <InputFormAccount
                  {...register("account_number")}
                  icon={<RiUserLine size={18} color={Colors.secondary40} />}
                  type="text"
                  label="Conta"
                  placeholder="Insira o número da conta..."
                  error={errors.account_number}
                />

                <InputFormAccount
                  {...register("digit")}
                  icon={<RiUserLine size={18} color={Colors.secondary40} />}
                  type="text"
                  label="Dígito"
                  placeholder="Insira o dígito da conta..."
                />
              </div>

              <FormFull>
                <ContainerInputFull isEditing>
                  <FormLabel>
                    <h2>Tipo de conta</h2>
                  </FormLabel>

                  <div className="select">
                    <Controller
                      control={control}
                      name="type_account"
                      rules={{ required: "Salutation is required" }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <StyledSelect
                          bordered={false}
                          labelInValue
                          placeholder="Selecione uma opção"
                          value={value ?? "Selecione uma opção"}
                          onChange={(e: any) => {
                            onChange(e.value);
                          }}
                          style={{
                            width: "100%",
                            minHeight: "100%",
                            outline: 0,
                            border: `2px solid ${Colors.secondary20}`,
                            borderRadius: "8px",
                            marginTop: "2px",
                          }}
                        >
                          <Option value={1} key={1}>
                            Corrente
                          </Option>

                          <Option value={2} key={2}>
                            Poupança
                          </Option>
                        </StyledSelect>
                      )}
                    />
                  </div>

                  {errors.type_account ? (
                    <span className="error">Insira um tipo válido</span>
                  ) : null}
                </ContainerInputFull>
              </FormFull>
            </ContainerBorderAccountForm>

            <div className="container-buttons">
              <ButtonPrev onClick={() => setStep(1)}>
                <span>Voltar</span>
              </ButtonPrev>

              <ButtonNext active type="submit">
                <span>Finalizar</span>
              </ButtonNext>
            </div>
          </FormAccount>
        ) : null}
      </ContainerModalReverse>
    </ModalStyled>
  );
}

import { authClient } from "services/authClient";
import {
  CreateTeamDTO,
  CreateTeamMemberDTO,
  ListTeam,
  UserResponse,
} from "./types";

export type SearchUserResponse = Array<UserResponse>;

export async function doCreateTeam(body: CreateTeamDTO) {
  return authClient.post("/team", body);
}

export async function handleSearchMemberByEmail(
  email: string
): Promise<SearchUserResponse> {
  const { data } = await authClient.get<SearchUserResponse>(
    `/team/user/invite/search?email=${email}`
  );

  return data;
}

export async function doCreateTeamMember({
  email,
  role,
  teamId,
}: CreateTeamMemberDTO) {
  const { data } = await authClient.post(`/team/${teamId}/user`, {
    email,
    role,
  });

  return data;
}

export async function getTeams(): Promise<ListTeam> {
  const { data } = await authClient.get<ListTeam>("/team");

  return data;
}

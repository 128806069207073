export interface TeamUsersDocument {
  _id: string;
  role: TeamUsersRoleEnum;
  invite_code: string;
  invite_status: TeamUsersInviteStatusEnum;
}

export enum TeamUsersRoleEnum {
  MANAGER = "manager",
  COORDINATOR = "coordinator",
  LEADER = "leader",
  STAFF = "staff",
  POINT_SALE = "point_sale",
  PARTNER = "partner",
}

export const convertToTeamRole = (role: string) => {
  if (role === "manager") return "Gestor";
  if (role === "coordinator") return "Coordenador";
  if (role === "leader") return "Líder do time";
  if (role === "staff") return "Staff";
  if (role === "point_sale") return "Ponto de Venda";
  if (role === "partner") return "Parceiro";

  return "Gestor";
};

export enum TeamUsersInviteStatusEnum {
  PENDING = "pending",
  REJECTED = "rejected",
  CONFIRMED = "confirmed",
}

export interface EventDTO {
  _id: string;
}

export interface UserDTO {
  _id: string;
  role?: TeamUsersRoleEnum;
}

export interface CreateTeamDTO {
  events: Array<EventDTO>;
  users: Array<UserDTO>;
  name: string;
  color: string;
}

export type UserResponse = {
  _id: string;
  name: string;
  email: string;
};

export interface CreateTeamMemberDTO {
  email: string;
  role: TeamUsersRoleEnum;
  teamId: string;
}

export interface ListTeam {
  results: Team[];
  count: number;
}

export interface Team {
  _id: string;
  user_id: UserId;
  events: Event[];
  users: User[];
  name: string;
  color: string;
  deleted_at: any;
  created_at: string;
  updated_at: string;
  __v: number;
}

export interface UserId {
  _id: string;
  name: string;
  email: string;
}

export interface Event {
  _id: Id;
}

export interface Id {
  _id: string;
  name: string;
  photo: string;
  thumb: string;
}

export interface User {
  _id: Id2;
  role: TeamUsersRoleEnum;
  invite_status: TeamUsersInviteStatusEnum;
}

export interface Id2 {
  _id: string;
  name: string;
  email: string;
}

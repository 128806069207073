import styled, { css } from "styled-components";
import { Colors } from "constants/colors";

interface CardAccordionProps {
  active?: boolean;
}

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  > .label {
    font-family: "Poppins";
    line-height: 160%;
    color: #a79fbc;
    font-size: 0.93rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  .ant-select-selector {
    border: 2px solid ${Colors.secondary20} !important;
    outline: none !important;
    border-radius: 8px !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 8px 20px !important;
    max-height: 45px !important;
    height: 45px !important;
  }

  .ant-select-selection-item {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 32px;
    color: ${Colors.secondary70};
  }
`;

export const SelectOption = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  > span {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 25.6px;
  }
`;

export const OptionHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  > strong {
    font-size: 14px;
    line-height: 25.6px;
    font-family: "Poppins";
    font-weight: 600;
    color: ${Colors.secondary100};
  }
`;

export const CardAccordion = styled.div<CardAccordionProps>`
  cursor: pointer;
  width: 100%;
  height: 5.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.68rem;

  background: ${(props) => (props.active ? Colors.black5 : Colors.white)};

  z-index: 2;

  border: 1px solid ${Colors.border50};
  box-shadow: 0px 4px 20px rgba(18, 25, 34, 0.05);
  border-radius: 0.93rem;

  padding: 0.89rem 1.92rem 1.17rem 1.96rem;

  div.content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    div.image {
      margin-top: 2px;

      @media (max-width: 800px) {
        margin-top: 5px;
      }
    }

    div.texts {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 23px;

      h2 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 1.03rem;

        margin-bottom: 0px;

        color: ${Colors.secondary100};

        @media (max-width: 800px) {
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;

          color: ${Colors.black100};
        }
      }

      p {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        margin-bottom: 0px;

        color: ${Colors.secondary40};

        @media (max-width: 800px) {
          font-weight: bold;
          font-size: 10px;
          line-height: 19px;

          color: ${Colors.secondary40};
        }
      }
    }
  }

  @media (max-width: 800px) {
    height: auto;
    padding: 7px 21px 3px 19px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 5px;

    svg {
      width: 13px;
      height: 13px;
    }

    div.content {
      width: 100%;
    }

    ${({ active }) =>
      !active
        ? css`
            height: 49px;

            .texts h2 {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 150px;
            }
          `
        : ""}
  }
`;

export const DataDropDown = styled.div`
  display: block;
  position: relative;

  width: 100%;
  z-index: 1;
  margin-top: -10px;

  ul {
    margin-top: 18px;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      margin-left: 17px;
    }

    li {
      width: 100%;
      list-style: none;
      height: 43px;

      margin-top: 10px;
      border-bottom: 1px solid ${Colors.border10};
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        margin-left: 17px;
      }
    }

    li.exit {
      border: 0;

      span {
        color: ${Colors.primary100};
      }
    }
  }
`;

export const ContainerBorder = styled.div`
  width: 100%;
  min-height: 5.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${Colors.border50};
  border-top: 0;
  box-sizing: border-box;
  border-radius: 0 0 20px 20px;
  padding-right: 20px;
  padding-bottom: 0.98rem;

  @media (max-width: 800px) {
    display: none;
  }

  div.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 70px;
    padding-left: 30px;
    width: 100%;

    div.column {
      display: flex;
      flex-direction: column;

      h2 {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        line-height: 160%;
        color: ${Colors.black80};
      }

      p {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 0.84rem;
        margin-bottom: 0px;
        color: ${Colors.black70};
      }
    }
  }

  div.container-image {
    margin-top: 40px;

    @media (max-width: 1200px) {
      padding-left: 10px;
    }

    img {
      border-radius: 10px;
    }
  }
`;

export const ContainerBorderMobile = styled.div`
  width: 100%;
  min-height: 180px;
  display: flex;
  flex-direction: column;

  border: 1px solid ${Colors.border50};
  border-top: 0;
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;
  margin-top: 15px;
  padding: 17px 15px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const RowMobileFirst = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;

  h6.row {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px;
    color: ${Colors.black50};
  }

  span.row {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black50};
    margin-left: 12px;
  }
`;

export const RowMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  h6.row {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px;
    color: ${Colors.black50};
  }

  span.row {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black50};
    margin-left: 12px;
  }
`;

export const RowMobileBorder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  padding-bottom: 12px;

  border-bottom: 1px solid ${Colors.secondary10};

  h6.row {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px;
    color: ${Colors.black50};
  }

  span.row {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black50};
    margin-left: 12px;
  }
`;

export const Form = styled.div`
  padding: 22px 40px;
  width: 100%;
`;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

import { TeamUsersRoleEnum } from "services/teams/types";
import * as yup from "yup";

type CollaboratorForm = {
  name: string;
  email: string;
  role: TeamUsersRoleEnum;
};

export type FormSchema = {
  collaborators: Array<CollaboratorForm>;
};

export const formSchema = yup.object().shape({
  collaborators: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required("Nome obrigatório"),
        email: yup
          .string()
          .email("E-mail inválido")
          .required("E-mail obrigatório"),
        role: yup.number().required("Tipo de acesso obrigatório"),
      })
    )
    .min(1, "É necessário adicionar ao menos um colaborador"),
});

export const ROLES = [
  {
    id: TeamUsersRoleEnum.MANAGER,
    title: "Gestor",
    description:
      "Possui acesso à todas as áreas de gerência, exceto ao financeiro.",
  },
  {
    id: TeamUsersRoleEnum.COORDINATOR,
    title: "Coordenador",
    description:
      "Possui acesso à todas as áreas de gerência, exceto ao financeiro e bilheteria.",
  },
  {
    id: TeamUsersRoleEnum.LEADER,
    title: "Líder do time",
    description:
      "Possui acesso à dashboard, ao controle de suas tarefas, tarefas do time e aos relatórios do gestor.",
  },
  {
    id: TeamUsersRoleEnum.STAFF,
    title: "Staff",
    description:
      "Possui acesso apenas à área de check-in, check-out e ao controle de suas tarefas.",
  },
  {
    id: TeamUsersRoleEnum.POINT_SALE,
    title: "Ponto de venda",
    description:
      "Possui acesso apenas à bilheteria, dashboard e ao controle de suas tarefas.",
  },
  {
    id: TeamUsersRoleEnum.PARTNER,
    title: "Parceiro",
    description:
      "Possui acesso apenas à visualização ao controle de suas tarefas.",
  },
];

import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  padding: calc(100px + 70px) 38px;
  background: ${Colors.white};

  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: calc(64px + 70px) 150px;
  }
`;

export const ResourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  margin-top: 50px;
`;

export const ResourceHeader = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;

  > span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 2rem;
    line-height: 3.2rem;
    color: ${Colors.black100};
  }
`;

export const ResourceList = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
`;

export const ResourceCard = styled.div<{ color: string }>`
  max-height: 120px;
  height: 120px;
  background: ${Colors.white};
  padding: 22px 20px 22px 12px;

  border: 1px solid #f0f0f0;
  border-radius: 14px;
  display: flex;
  align-items: center;
  column-gap: 22px;

  .info-wrapper {
    display: flex;
    flex-direction: column;
  }

  .info-container {
    display: flex;
    align-items: center;
  }

  span {
    flex-shrink: 1;

    font-family: "Open Sans";
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 700;
    color: ${({ color }) => color};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  p {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.6rem;
    color: ${Colors.black30};
  }

  b {
    font-family: "Cairo";
    font-size: 1.625rem;
    font-weight: 700;
    color: ${Colors.black100};
  }

  .link-icon {
    margin-left: auto;
  }
`;

export const ResourceIcon = styled.div<{ color: string }>`
  padding: 10px;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background: ${({ color }) => color};
`;

import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { useUserPurchase } from "services/Hook/User";
import { useDisclosure } from "hooks/useDisclosure";
import { Purchase } from "types";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import {
  Container,
  ContainerItems,
  ContainerAllItems,
  ModalStyled,
  ButtonConfirm,
  CardTerms,
  ContainerButtonsCancel,
  ContainerModalConfirmReverse,
  ArticleContP,
  ArticleContSpan,
  ArticleContPLast,
} from "./styles";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { ModalConfirmReverse } from "components/ModalConfirmReverse";
import { ListBreadcrumb } from "components/ListBreadcrumb";

import img_reverse_success from "assets/img-reverse-sucess.svg";
import img_reverse_error from "assets/img-reverse-error.svg";

type Meta = {
  total: number;
  last_page: number;
};

interface PurchaseInteface {
  data: Purchase[];
  meta: Meta;
}

export default function TermsCancel() {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const {
    handleCancel: handleCancelConfirm,
    handleOk: handleOkConfirm,
    isModalVisible: isModalVisibleConfirm,
    showModal: showModalConfirm,
  } = useDisclosure();

  const {
    handleCancel: handleCancelError,
    handleOk: handleOkError,
    isModalVisible: isModalVisibleError,
    showModal: showModalError,
  } = useDisclosure();

  const {
    handleCancel: handleCancelReverse,
    handleOk: handleOkReverse,
    isModalVisible: isModalVisibleReverse,
    showModal: showModalReverse,
  } = useDisclosure();

  const { purchase_id } = useParams();

  const [purchase, setPurchase] = useState<Purchase | null>(null);

  const [error, setError] = useState(false);

  const { data: dataPurchasings } = useUserPurchase<PurchaseInteface>(1);

  useEffect(() => {
    async function getPurchaseById() {
      const purchaseById = dataPurchasings?.data.find(
        (purchase) => purchase._id === purchase_id
      );

      if (purchaseById) {
        setPurchase(purchaseById);
      } else {
        setError(true);
        alert("Esta compra não existe");
      }
    }

    getPurchaseById();
  }, [dataPurchasings?.data, purchase_id]);

  if (error) {
    return <></>;
  }

  return (
    <>
      <Header isProfile />

      <Container>
        <ContainerItems>
          <ListBreadcrumb
            data={[
              { name: "Home", link: "/" },
              { name: "Perfil", link: "/profile/my-shopping" },
              { name: "Termo de cancelamento", link: "/" },
            ]}
          />

          <div className="header-title">
            <h1 className="title">Cancelamento de compra</h1>
          </div>
          <ContainerAllItems>
            <CardTerms>
              <div className="header">
                <h4>Termos de cancelamento da compra</h4>

                <div className="date-header">
                  <p>03/08/21 - Atualizado</p>
                </div>
              </div>

              <ArticleContP>
                1. Em caso de arrependimento do cliente, para compras realizadas
                via site ou aplicativo, o reembolso do valor da compra será
                efetuado, desde que esse direito seja requisitado em até 07
                (sete) dias da data da compra e que esteja em até 02 (dois) dias
                de antecedência à data do evento. Para compras realizadas com
                menos de 07 (sete) dias de antecedência ao evento, os ingressos
                só poderão ser cancelados em até 02 (dois) dias antes da
                apresentação. A devolução dos valores pagos seguirá o
                procedimento abaixo descrito, de acordo com a forma de pagamento
                utilizada:
              </ArticleContP>

              <ArticleContP>
                1.2 Cartão de crédito: o estorno do valor será solicitado à
                operadora do cartão, que disponibilizará o crédito em fatura
                seguinte ou subsequente. Após o cancelamento da compra, seu
                reembolso aparecerá na fatura do mesmo cartão usado durante a
                compra em até 90 dias. Essa regra vale tanto para cartões
                físicos quanto virtuais. O reembolso de uma compra paga com
                cartão de crédito é feito sempre para a operadora do cartão, que
                normalmente devolve o valor diretamente na fatura do cartão
                usado na compra. Caso você não tenha mais o cartão usado na
                compra, a operadora do seu cartão pode optar por te reembolsar
                das seguintes formas: Caso exista saldo devedor em outro cartão
                da mesma operadora, o valor poderá ser reembolsado na fatura
                deste outro cartão Caso um novo cartão tenha sido emitido por
                questões de upgrade ou vencimento, o valor poderá ser
                reembolsado na fatura do cartão atual Se nenhuma das opções
                acima se aplica ao seu caso, sugerimos que entre em contato com
                a operadora do seu cartão para checar como seu reembolso será
                feito.
              </ArticleContP>

              <ArticleContP>
                1.3 Boleto ou Pix: depósito bancário em até 90 dias úteis. Após
                o cancelamento da compra, você será direcionado para preencher
                um formulário de reembolso. É possível receber o reembolso tanto
                em conta corrente quanto conta poupança. Após preencher o
                formulário de reembolso, basta aguardar um prazo de até 90 dias.
                Atenção! É preciso informar uma conta associada ao mesmo CPF ou
                CNPJ informado no momento da compra. Caso você informe uma conta
                com CPF ou CNPJ incorreto, você receberá uma mensagem de falha e
                poderá tentar novamente. Caso você não tenha uma conta bancária
                para receber o valor ou tenha ficado com alguma dúvida, fale com
                a gente! Informamos que os prazos discriminados no item 1.2 para
                estornos via cartão de crédito, pode variar, de acordo com o
                fechamento de sua fatura e/ou seu banco emissor.
              </ArticleContP>

              <ArticleContSpan>Importante!</ArticleContSpan>

              <ArticleContPLast>
                Os eventos presenciais alterados ou cancelados devido à pandemia
                da COVID-19 podem ter uma política de reembolso diferente da
                citada neste texto, com base na Lei 14.046/2020. Para conferir
                qual é a política aplicada no seu evento, lembre-se de acessar a
                página do evento ou entre em contato com nosso time.
              </ArticleContPLast>
            </CardTerms>
          </ContainerAllItems>

          <ContainerButtonsCancel>
            <button className="cancel" onClick={showModalReverse}>
              <span>Cancelar compra</span>
            </button>

            <button
              className="confirm"
              onClick={() => navigate("/profile/my-shopping")}
            >
              <span>Desistir do cancelamento</span>
            </button>
          </ContainerButtonsCancel>
        </ContainerItems>

        <ModalStyled
          width={642}
          visible={isModalVisibleError}
          onOk={handleOkError}
          onCancel={handleCancelError}
          closeIcon={null}
          footer={null}
        >
          <ContainerModalConfirmReverse>
            <img
              src={img_reverse_error}
              width={189}
              height={189}
              alt="confirm-cancel"
            />

            <div className="texts-confirm">
              <h3>Falha no estorno!</h3>

              <p>
                O estorno solicitado não foi realizado, Tente novamente mais
                tarde.
              </p>
            </div>

            <div className="buttons">
              <ButtonConfirm
                onClick={() => {
                  handleOkError();
                }}
              >
                <span>Ok</span>
              </ButtonConfirm>
            </div>
          </ContainerModalConfirmReverse>
        </ModalStyled>

        <ModalStyled
          width={642}
          visible={isModalVisibleConfirm}
          onOk={handleOkConfirm}
          onCancel={handleCancelConfirm}
          closeIcon={null}
          footer={null}
        >
          <ContainerModalConfirmReverse>
            <img
              src={img_reverse_success}
              width={189}
              height={189}
              alt="confirm-cancel"
            />

            <div className="texts-confirm">
              <h3>Estorno realizado!</h3>

              <p>
                O estorno foi realizado com sucesso. O valor da sua compra será
                devolvido à sua fatura em até 90 dias úteis.
              </p>
            </div>

            <div className="buttons">
              <ButtonConfirm
                onClick={() => {
                  handleOkConfirm();
                  navigate("/profile/my-shopping");
                }}
              >
                <span>Ok</span>
              </ButtonConfirm>
            </div>
          </ContainerModalConfirmReverse>
        </ModalStyled>

        <ModalConfirmReverse
          purchase={purchase}
          purchase_id={Number(purchase_id)}
          handleOk={handleOkReverse}
          handleCancel={handleCancelReverse}
          isModalVisible={isModalVisibleReverse}
          showModalConfirm={showModalConfirm}
          showModalError={showModalError}
        />
      </Container>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}

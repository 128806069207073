import { Header } from "components/Header";
import {
  Container,
  ResourceCard,
  ResourceContainer,
  ResourceHeader,
  ResourceIcon,
  ResourceList,
} from "./styles";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { RiTeamLine } from "react-icons/ri";
import { Colors } from "constants/colors";
import { FiChevronRight } from "react-icons/fi";
import { TeamsTable } from "./components/TeamsTable";
import { Footer } from "components/Footer";
import { useTeamsList } from "services/Hook/Team";
import { Pagination } from "components/Pagination";
import { useSearchTeamMember } from "hooks/useSearchTeamMember";
import { applyOpacityToHexColor } from "utils/applyOpacityToHexColor";
import { useCallback, useMemo } from "react";
import { convertToTeamRole, Team } from "services/teams/types";
import { DropDownSearch } from "components/DropDownSearch";

const metaTags = {
  title: `Times - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

interface TableRow {
  id: string;
  name: string;
  email: string;
  whatsapp: string;
  event: string;
  team: string;
  role: string;
}

function Teams() {
  const { data } = useTeamsList();
  const { onChange, page } = useSearchTeamMember();

  const transformResults = useCallback(
    (team: Team[]) => {
      const transformed: Array<TableRow> = [];

      data.results.forEach((team) => {
        const teamName = team.name;

        team.users.forEach((teamUser) => {
          const userId = teamUser._id._id;
          const userName = teamUser._id.name;
          const userEmail = teamUser._id.email;
          const userRole = convertToTeamRole(teamUser.role);

          const userWhatsapp = "-------";

          const userEvent =
            team.events.length > 0 ? team.events[0]._id.name : "";

          transformed.push({
            id: userId,
            name: userName,
            email: userEmail,
            whatsapp: userWhatsapp,
            event: userEvent,
            team: teamName,
            role: userRole,
          });
        });
      });

      return transformed;
    },
    [data.results]
  );

  const dataRows = useMemo(() => {
    return transformResults(data.results);
  }, [data.results, transformResults]);

  return (
    <>
      <Header metaTags={metaTags} />

      <Container>
        <div className="bread">
          <ListBreadcrumb
            data={[
              { name: "Home", link: "/" },
              { name: "Times", link: "/teams" },
            ]}
          />
        </div>

        <ResourceContainer>
          <ResourceHeader>
            <RiTeamLine color={Colors.black100} size={36} />

            <span>Times</span>
          </ResourceHeader>

          <ResourceList>
            {data.results.map((item) => (
              <ResourceCard key={item._id} color={item.color}>
                <ResourceIcon color={applyOpacityToHexColor(item.color)}>
                  <RiTeamLine size={40} color={item.color} />
                </ResourceIcon>

                <div className="info-wrapper">
                  <span>{item.name}</span>
                </div>

                <FiChevronRight className="link-icon" size={20} />
              </ResourceCard>
            ))}
          </ResourceList>
        </ResourceContainer>

        <ResourceContainer>
          <ResourceHeader>
            <span>Colaboradores</span>
          </ResourceHeader>

          <div>
            <DropDownSearch />

            <TeamsTable contributors={dataRows} />
          </div>

          {data && data?.results?.length > 0 ? (
            <div className="pagination">
              <Pagination
                title="registros"
                totalCountOfRegisters={data?.count}
                currentPage={Number(page)}
                onPageChange={onChange}
                registersPerPage={10}
              />
            </div>
          ) : null}
        </ResourceContainer>
      </Container>

      <Footer />
    </>
  );
}

export default Teams;

import { getTeams } from "services/teams";
import useSWR from "swr";

export function useTeamsList() {
  const url: string = "/team";

  const { data, error, mutate } = useSWR(url, async (url) => {
    const data = await getTeams();

    const results = data;

    return results;
  });

  return { data: data ?? { count: 0, results: [] }, error, mutate };
}

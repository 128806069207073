import { useEffect, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Checkbox, Spin } from "antd";

import { RiCloseCircleLine } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import { BiCalendarCheck } from "react-icons/bi";

import type { CheckboxValueType } from "antd/es/checkbox/Group";

import { useFiltersContext } from "contexts/FiltersContext";

import { Colors } from "constants/colors";

import { LoadingOutlined } from "@ant-design/icons";

import {
  ButtonSearch,
  ContainerInputSearch,
  ContainerModal,
  ContainerSelect,
  ModalStyled,
  NormalCardBorder,
  CheckboxStyled,
  ContainerButtonsModal,
  ButtonClear,
  ButtonOk,
  Center,
} from "./styles";

import { useSearchMyEvent } from "hooks/useSearchMyEvent";
import { EventOrganizer } from "types";

interface ModalFilterByEventProps {
  onSelectEvents?: (events: Array<EventOrganizer>) => void;
  onClearEvents?: () => void;
}

export function ModalFilterByEvent({
  onSelectEvents,
  onClearEvents,
}: ModalFilterByEventProps) {
  const myRef = useRef(null);

  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);

  const executeScroll = () => scrollToRef(myRef);

  const [searchParams, setSearchParams] = useSearchParams();

  const [textSearch, setTextSearch] = useState("");

  const [selectedItems, setSelectedItems] = useState<CheckboxValueType[]>([]);

  const { isOpenFilterModalEvent, setIsOpenFilterModalEvent } =
    useFiltersContext();

  const events = searchParams.get("events") ?? "";
  const organizer = searchParams.get("organizer") ?? "";

  const {
    data: dataEvents,
    hasMoreItems,
    handleLoadMoreItemsMobile,
    loadingMoreItems,
  } = useSearchMyEvent();

  const dataEventsFilterBy = {
    ...dataEvents,
    results:
      organizer === ""
        ? dataEvents?.results
        : dataEvents?.results?.filter(
            (event) => event?.organizer?._id === organizer
          ),
  };

  const data = {
    ...dataEventsFilterBy,
    results:
      textSearch === ""
        ? dataEventsFilterBy?.results
        : dataEventsFilterBy?.results?.filter((event) =>
            event?.name.includes(textSearch)
          ),
  };

  useEffect(() => {
    if (events) {
      const eventsBy = events?.split(",");

      setSelectedItems(eventsBy);
    } else {
      setSelectedItems([]);
    }
  }, [events]);

  const closeModal = () => {
    setIsOpenFilterModalEvent(false);

    searchParams.delete("name_event");
  };

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedItems(checkedValues);
  };

  const handleAddFilterEvent = () => {
    if (selectedItems.length === 0) {
      return;
    }

    const events = selectedItems.join(",");

    if (onSelectEvents && Boolean(dataEvents?.results)) {
      const filteredEvents = dataEvents?.results?.filter((i) =>
        selectedItems.includes(i._id)
      );

      if (filteredEvents) {
        onSelectEvents(filteredEvents);
      }
    }

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      events,
    });

    closeModal();
  };

  const handleScroll = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    if (scrollHeight - scrollTop > clientHeight && !loadingMoreItems) {
      handleLoadMoreItemsMobile();
      executeScroll();
    }
  };

  const clear = () => {
    setSelectedItems([]);
    onClearEvents!();

    setTextSearch("");

    searchParams.delete("events");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.primary100 }} spin />
  );

  return (
    <ModalStyled
      width={857}
      visible={isOpenFilterModalEvent}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>Filtrar por evento</h1>

        <h2>
          Selecione um ou mais eventos para a visualização dos resultados.
        </h2>

        <ContainerInputSearch>
          <input
            type="text"
            placeholder="Insira o nome do evento..."
            onChange={(e) => setTextSearch(e.target.value)}
          />

          <ButtonSearch onClick={() => {}}>
            <FiSearch size={18} color={Colors.primary100} />
          </ButtonSearch>
        </ContainerInputSearch>

        <ContainerSelect
          onScroll={(e) => {
            if (!!hasMoreItems) {
              handleScroll(e);
            }
          }}
        >
          <Checkbox.Group
            style={{ width: "100%" }}
            value={selectedItems}
            onChange={onChange}
          >
            {data?.results?.map((event) => (
              <NormalCardBorder>
                <div className="check">
                  <CheckboxStyled
                    value={event?._id}
                    checked={selectedItems.includes(event?._id)}
                  />
                </div>

                <div className="circle-icon">
                  <BiCalendarCheck size={16} color={Colors.secondary100} />
                </div>

                <h3 className="normal">{event?.name}</h3>
              </NormalCardBorder>
            ))}
          </Checkbox.Group>

          <Center>
            {loadingMoreItems ? (
              <Spin spinning={loadingMoreItems} indicator={antIcon} />
            ) : null}
          </Center>
        </ContainerSelect>

        <ContainerButtonsModal>
          <ButtonClear onClick={clear}>
            <span>Limpar</span>
          </ButtonClear>

          <ButtonOk onClick={handleAddFilterEvent}>
            <span>Aplicar filtros</span>
          </ButtonOk>
        </ContainerButtonsModal>
      </ContainerModal>
    </ModalStyled>
  );
}

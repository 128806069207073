import { MdSend } from "react-icons/md";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiAddLine } from "react-icons/ri";
import { Colors } from "constants/colors";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import {
  Box,
  BoxHeader,
  Container,
  Title,
  AddCollaborator,
  CollaboratorsWrapper,
  ActionsContainer,
  CancelButton,
  ConfirmButton,
} from "./styles";
import AccordionNewCollaborator from "./components/AccordionNewCollaborator";
import { FormSchema, formSchema } from "./misc";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  errorNotification,
  successNotification,
} from "components/Notification";
import { doCreateTeamMember } from "services/teams";
import axios from "axios";

const metaTags = {
  title: `Novo colaborador - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

type Params = {
  id: string;
};

function CollaboratorRegister() {
  const { id: teamId } = useParams<Params>();
  const { control, register, watch, handleSubmit } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  });
  const { fields, append } = useFieldArray({
    control,
    name: "collaborators",
  });

  const handleAddCollaborator = () => {
    append({ email: undefined, name: undefined, role: undefined });
  };

  const onSubmit = async (data: FormSchema) => {
    try {
      if (!teamId) return;

      data.collaborators.forEach(async (member) => {
        await doCreateTeamMember({
          email: member.email,
          role: member.role,
          teamId,
        });

        successNotification("Sucesso.", "Convite enviado com sucesso.");
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        errorNotification(
          "Erro ao cadastrar o time",
          error?.response?.data?.message
        );

        return;
      }

      errorNotification(
        "Erro ao cadastrar o time",
        "Tente novamente mais tarde"
      );
    }
  };

  return (
    <>
      <Header metaTags={metaTags} />

      <Container onSubmit={handleSubmit(onSubmit)}>
        <div className="bread">
          <ListBreadcrumb
            data={[
              { name: "Home", link: "/" },
              { name: "Times", link: "/teams" },
              { name: "Colaborador", link: "/collaborators/new" },
            ]}
          />
        </div>

        <Title>Novo colaborador</Title>

        <Box>
          <BoxHeader>
            <h2>Cadastre um novo colaborador ao seu time</h2>

            <p>
              Envie convites para novos colaboradores para adicioná-los em seu
              time
            </p>
          </BoxHeader>

          <CollaboratorsWrapper>
            {fields.map((field, index) => (
              <AccordionNewCollaborator
                collaborator={{
                  name: watch(`collaborators.${index}.name`),
                  email: watch(`collaborators.${index}.email`),
                  role: watch(`collaborators.${index}.role`),
                }}
                key={field.id}
                index={index}
                register={register}
                control={control}
              />
            ))}
          </CollaboratorsWrapper>

          <AddCollaborator onClick={handleAddCollaborator} type="button">
            <RiAddLine />
            Adicionar colaborador
          </AddCollaborator>
        </Box>

        <ActionsContainer>
          <CancelButton>Voltar</CancelButton>
          <ConfirmButton>
            <MdSend color={Colors.white} size={28} />
            Enviar convite
          </ConfirmButton>
        </ActionsContainer>
      </Container>
    </>
  );
}

export default CollaboratorRegister;

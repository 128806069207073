import { Modal } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h3.date {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    margin-bottom: 0px;

    color: ${Colors.black50};
  }
`;

export const ContainerShoppingsMobile = styled.div`
  display: flex;
  flex-direction: column;

  h2.history {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    margin-bottom: 0px;
    text-transform: uppercase;
    color: ${Colors.gray20};

    opacity: 0.75;
  }
`;

export const Center = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardShopping = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  padding-bottom: 10px;

  background: ${Colors.white};
  margin-bottom: 20px;

  border: 1px solid ${Colors.black10};
  box-sizing: border-box;
  border-radius: 5px;

  padding: 10px 20px 0px 18px;

  div.ticket-quantity {
    width: 100%;
    height: 37px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    border-bottom: 1px solid ${Colors.secondary10};

    div.badge {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${Colors.white30};

      span {
        font-family: "Cairo";
        font-size: 10px;
        line-height: 19px;
        font-weight: 400;
        display: flex;
        align-items: center;
        text-align: center;

        color: ${Colors.black};
      }
    }

    h2 {
      font-family: "Poppins";
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      display: flex;
      align-items: center;

      color: ${Colors.black50};
      margin-top: 4px;
      margin-left: 8px;
    }
  }

  div.content {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid ${Colors.secondary10};

    div.container-texts {
      display: flex;
      flex-direction: row;

      div.container-image {
        img {
          border-radius: 5px;
        }
      }

      div.texts {
        height: 100%;
        display: flex;
        flex-direction: column;

        justify-content: flex-start;
        margin-left: 10px;

        h2 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          margin-bottom: 0px;

          color: ${Colors.black100};
        }

        p {
          font-family: "Cairo";
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 19px;

          display: flex;
          align-items: center;

          display: flex;
          align-items: center;
          margin-top: 4px;
          color: ${Colors.black50};
        }
      }
    }
  }

  div.buttons {
    width: 100%;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    a {
      width: 50%;
    }

    button {
      border: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background-color: transparent;
      font-family: "Cairo";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: ${Colors.primary100};
    }
  }
`;

export const ContainerModal = styled.div`
  margin-top: 0px;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 30px;

  padding: 0;

  div.title {
    h2.title {
      font-weight: 600;
      font-size: 1.87rem;
      text-align: center;
      color: ${Colors.secondary100};
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  div.container-web {
    width: 100%;
    padding-left: 37px;
    padding-right: 37px;
    @media (max-width: 1024px) {
      display: none;
    }

    div.cancel-purchasing {
      margin-top: 3.75rem;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      button {
        cursor: pointer;
        border: 0;

        width: 18.23rem;
        height: 3.84rem;

        background: ${Colors.primary10};
        border-radius: 0.65rem;

        span {
          font-weight: bold;
          font-size: 1.21rem;
          margin-bottom: 0px;

          text-align: center;
          color: ${Colors.primary100};
        }
      }
    }

    footer.help-you {
      display: flex;
      flex-direction: column;
      margin-top: 3.75rem;
      justify-content: flex-end;
      padding-top: 3.75rem;
      border-top: 1px solid ${Colors.secondary10};

      h1 {
        text-align: left;
        font-weight: bold;
        font-size: 1.21rem;

        color: ${Colors.black30};
        margin-bottom: 1.4rem;
      }

      div.drop {
        cursor: pointer;
        width: 100%;
        height: 5.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-left: 1.4rem;
        padding-right: 1.82rem;

        background: ${Colors.white};

        border: 1px solid ${Colors.black10};
        box-sizing: border-box;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
        border-radius: 0.93rem;

        div.first {
          display: flex;
          flex-direction: row;
          align-items: center;

          div.badge {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.81rem;
            height: 2.81rem;

            background: ${Colors.primary10};
            border-radius: 0.93rem;
          }

          h2 {
            color: ${Colors.primary100};
            font-weight: normal;
            font-size: 1.21rem;

            margin-left: 0.93rem;
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  div.container-mobile {
    width: 100%;
    @media (min-width: 1024px) {
      display: none;
    }

    footer.help-you {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      justify-content: flex-end;
      padding-top: 24px;
      border-top: 1px solid ${Colors.secondary10};

      h1 {
        text-align: left;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;

        text-transform: uppercase;

        color: ${Colors.gray20};

        opacity: 0.75;
        margin-bottom: 10px;
      }

      div.drop {
        cursor: pointer;
        width: 100%;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-left: 19px;
        padding-right: 5px;

        background: ${Colors.white};
        border: 1px solid ${Colors.black10};
        box-sizing: border-box;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
        border-radius: 8px;

        div.first {
          display: flex;
          flex-direction: row;
          align-items: center;

          div.badge {
            display: flex;
            align-items: center;
            justify-content: center;

            background: ${Colors.white};
          }

          h2 {
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            margin-left: 13px;

            color: ${Colors.secondary40};
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`;

export const ContainerDetailsShoping = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding: 49px 50px 44px 50px;

  margin-top: 1.12rem;

  background: ${Colors.white};
  border: 1px solid ${Colors.black10};
  box-sizing: border-box;
  border-radius: 20px;

  div.header-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 30px;
    border-bottom: 1px solid ${Colors.secondary10};

    div.badge-content {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 14.34rem;
      height: 2.25rem;

      background: ${Colors.white30};
      border-radius: 0.23rem;

      h3 {
        font-weight: 700;
        font-size: 0.93rem;

        margin-bottom: 0px;
        color: ${Colors.black100};
      }
    }

    div.content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      img {
        width: 44px;
        height: 44px;
        border-radius: 0.6rem;
      }

      div.column {
        display: flex;
        flex-direction: column;
        margin-left: 24px;

        h2 {
          font-weight: 600;
          /* font-size: 26px; */
          font-size: 1.21rem;

          margin-bottom: 0px;
          color: ${Colors.black100};
        }

        span {
          font-weight: 400;
          /* font-size: 20px; */
          font-size: 0.93rem;

          color: ${Colors.black50};
          margin-top: 0.46rem;
        }

        a {
          font-weight: bold;
          font-size: 0.93rem;
          text-decoration-line: underline;
          color: ${Colors.primary100};

          margin-top: 0.56rem;
        }
      }
    }
  }
`;

export const ContainerBorderShipping = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;

  padding-bottom: 30px;
  border-bottom: 1px solid ${Colors.secondary10};

  div.badge-quantity {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 60px;

    background: ${Colors.white30};

    h4 {
      font-weight: 600;
      font-size: 26px;
      line-height: 160%;
      margin-bottom: 0px;

      color: ${Colors.black100};
    }
  }

  div.content-border {
    display: flex;
    flex-direction: column;

    h2.title-border {
      font-weight: 500;
      font-size: 22px;
      line-height: 160%;
      margin-bottom: 0px;

      color: ${Colors.secondary100};
    }

    div.row {
      margin-top: 12px;
      strong {
        font-weight: bold;
        font-size: 26px;
        line-height: 160%;

        color: ${Colors.black100};
      }

      span {
        font-weight: 600;
        font-size: 22px;
        line-height: 160%;

        text-decoration-line: line-through;
        margin-left: 15px;

        color: ${Colors.black40} #a0a1ab;
      }
    }
  }
`;

export const ContainerBorderNormalShipping = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  padding-bottom: 30px;
  border-bottom: 1px solid ${Colors.secondary10};

  div.badge-quantity {
    display: flex;
    align-items: center;
    justify-content: center;

    /* width: 50px;
    height: 60px; */

    width: 2.34rem;
    height: 2.81rem;

    background: ${Colors.white30};

    h4 {
      font-weight: 600;
      font-size: 1.21rem;

      margin-bottom: 0px;

      color: ${Colors.black100};
    }
  }

  div.content-border {
    display: flex;
    flex-direction: column;

    h2.title-border {
      font-weight: 700;
      font-size: 1.03rem;

      margin-bottom: 0px;

      color: ${Colors.secondary100};
    }

    div.row {
      margin-top: 0.56rem;

      strong {
        font-weight: bold;
        font-size: 1.21rem;
        margin-bottom: 0px;

        color: ${Colors.black100};
      }

      span {
        font-weight: 600;
        font-size: 22px;
        line-height: 160%;

        text-decoration-line: line-through;
        margin-left: 15px;

        color: ${Colors.black40} #a0a1ab;
      }
    }
  }
`;

export const ContainerModalTypePayment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.4rem;

  padding-bottom: 1.4rem;

  border-bottom: 1px solid ${Colors.secondary10};

  h3.type {
    margin-bottom: 0px;

    font-weight: 600;
    font-size: 1.03rem;
    margin-bottom: 0px;

    color: ${Colors.black100};
  }

  div.t-payment {
    display: flex;
    align-items: center;

    span.price {
      font-weight: 600;
      font-size: 1.03rem;
      margin-bottom: 0px;

      color: ${Colors.black100};
    }
  }
`;

export const ContainerModalSubtotal = styled.div`
  margin-top: 2.25rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  padding-bottom: 1.96rem;

  border-bottom: 1px solid ${Colors.secondary10};

  div.row-sub {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 1.4rem;

    h3 {
      margin-bottom: 0px;
      font-weight: 500;
      font-size: 1.03rem;

      color: ${Colors.black100};
    }

    span {
      font-weight: 600;
      font-size: 1.31rem;
      margin-bottom: 0px;

      color: ${Colors.black100};
    }
  }
`;

export const ContainertModalTotal = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 1.21rem;

    color: ${Colors.black100};
  }

  span {
    font-weight: bold;
    font-size: 1.68rem;

    color: ${Colors.black100};
  }
`;

export const ListPurchasesItems = styled.div`
  display: flex;
  flex-direction: column;

  > div.ticket-quantity + div.ticket-quantity {
    margin-top: 6px;
  }
`;

export const DivEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;

  h2.empty {
    font-weight: bold;
    font-size: 26px;
    line-height: 160%;
    color: ${Colors.gray10};
  }

  p.empty {
    font-weight: normal;
    font-size: 22px;
    line-height: 160%;
    text-align: center;
    color: ${Colors.gray20};
  }
`;

export const BadgeMobile = styled.div`
  margin-top: 27px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 32px;

  background: ${Colors.white30};
  border-radius: 5px;

  h4 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black100};
    margin-bottom: 0px;
  }
`;

export const BorderNormalPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid ${Colors.secondary10};

  div.column {
    display: flex;
    flex-direction: column;

    h3 {
      font-weight: normal;
      font-size: 12px;
      line-height: 100%;

      color: ${Colors.black};
    }

    strong {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;

      color: ${Colors.black};
    }
  }

  div.quantity {
    h4 {
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;
      text-align: center;

      color: ${Colors.black};
    }
  }
`;

export const BorderSmallPrice = styled.div`
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 8px;
  border-bottom: 1px solid ${Colors.secondary10};

  h2.title-card {
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;

    display: flex;
    align-items: center;

    color: ${Colors.black};
    margin-bottom: 0px;
  }

  div.t-payment {
    display: flex;
    align-items: center;

    span.price {
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;

      color: ${Colors.black100};
    }
  }
`;

export const ButtonCancelPurchaseMobile = styled.button`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  background: ${Colors.primary10};
  border-radius: 8px;
  margin-top: 40px;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;

    color: ${Colors.primary100};
  }
`;

export const ContainerPurchaseDetails = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 0px;

  @media (min-width: 1024px) {
    display: none;
  }

  div.header {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between;

    h1 {
      font-weight: bold;
      font-size: 16px;

      margin-bottom: 0px;
      margin-top: 0px;
      color: ${Colors.black100};
    }
  }
`;

export const PurchaseDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  div.borders {
    margin-top: 28px;
  }

  div.prices {
    margin-top: 47px;

    display: flex;
    flex-direction: column;
    padding-bottom: 9px;
    border-bottom: 1px solid ${Colors.secondary10};

    div.row-price {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  div.total-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;

    h3 {
      font-weight: 600;
      font-size: 22px;
      line-height: 25px;

      color: ${Colors.black100};
    }

    h5 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;

      color: ${Colors.black100};
    }
  }

  div.shopping-event {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-top: 1px solid ${Colors.secondary10};
    padding-top: 24px;

    div.container {
      display: flex;
      flex-direction: row;

      div.container-image {
        img {
          border-radius: 5px;
        }
      }

      div.content {
        margin-left: 12px;

        h3 {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 0px;
          color: ${Colors.black100};
        }

        span {
          font-weight: 600;
          font-size: 11px;
          line-height: 21px;

          display: flex;
          align-items: center;

          color: ${Colors.black50};
        }
      }
    }

    div.view-event {
      height: 100%;
      align-items: flex-end;
      justify-content: flex-end;

      button {
        border: 0;
        cursor: pointer;
        background-color: transparent;

        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        display: flex;
        align-items: center;

        color: ${Colors.primary100};
      }
    }
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }

  @media (max-width: 800px) {
    padding: 0px;
  }
`;

export const ContainerModalConfirm = styled.div`
  margin-top: 53px;

  max-width: 75.89rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  div.texts-confirm {
    margin-top: 29px;

    h3 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;

      font-size: 1.5rem;
      margin-bottom: 0px;
      text-align: center;

      color: ${Colors.secondary100};
    }

    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;

      font-size: 0.93rem;

      text-align: center;
      color: ${Colors.black90};
    }
  }

  div.buttons {
    margin-top: 37.5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ButtonClose = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  width: 12.32rem;
  height: 2.81rem;

  background: ${Colors.primary10};
  border-radius: 14px;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    text-align: center;
    color: ${Colors.primary100};
  }
`;

export const ButtonConfirm = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;

  width: 12.32rem;
  height: 2.81rem;

  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 14px;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;

    font-size: 0.93rem;
    text-align: center;

    color: ${Colors.white};
  }
`;

import { ReactNode } from "react";
import {
  Container,
  EndAdornmentContainer,
  ItemsSelectedContainer,
  TitleContainer,
} from "./styles";

export { ItemsSelectedContainer };

interface ItemSelectedProps {
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  title: string;
  description?: string;
}

function ItemSelected({
  startAdornment,
  endAdornment,
  title,
  description,
}: ItemSelectedProps) {
  return (
    <Container>
      {startAdornment}

      <TitleContainer>
        <span>{title}</span>

        {description ? <span>{description}</span> : null}
      </TitleContainer>

      <EndAdornmentContainer>{endAdornment}</EndAdornmentContainer>
    </Container>
  );
}

export default ItemSelected;

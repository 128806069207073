import styled, { css } from "styled-components";
import { Colors } from "constants/colors";

export const Title = styled.h1`
  margin-top: 54px;

  font-size: 2.5rem;
  line-height: 4rem;
  font-weight: 700;
  color: ${Colors.black100};
`;

export const Container = styled.form`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  padding: calc(100px + 70px) 38px;
  background: ${Colors.white};

  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: calc(64px + 70px) 150px;

    div.bread {
      display: none;
    }
  }
`;

export const Box = styled.div`
  margin-top: 50px;
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 40px;

  border: 1px solid ${Colors.black10};
  background: ${Colors.white};
  border-radius: 20px;

  padding: 64px 135px;
`;

export const BoxHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-bottom: 40px;

  border-bottom: 1px solid ${Colors.black10};

  > h2 {
    font-family: "Poppins";
    font-size: 1.625rem;
    line-height: 2.6rem;
    font-weight: 600;
    color: ${Colors.secondary100};
  }

  > p {
    font-family: "Open Sans";
    font-size: 1.375rem;
    line-height: 2.2rem;
    color: ${Colors.black70};
    margin: 0;
  }
`;

export const ButtonBase = css`
  cursor: pointer;

  max-width: 390px;
  width: 100%;
  padding: 20px 0;

  border-radius: 14px;

  font-family: "Cairo";
  font-size: 1.25rem;
  line-height: 2.6rem;
  font-weight: 700;
  border: none;
  outline: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 720px) {
    height: 40px;
    padding: 0;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.2;
  }
`;

export const CollaboratorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddCollaborator = styled.button`
  ${ButtonBase}

  max-width: 400px;
  padding: 14px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  font-size: 1.25rem;
  line-height: 2.6rem;
  font-weight: 700;
  color: ${Colors.primary100};
  background-color: ${Colors.primary10};

  svg {
    color: ${Colors.primary100};
  }

  &:hover {
    opacity: 0.8;
    color: ${Colors.primary100};
  }
`;

export const ActionsContainer = styled.div`
  margin-top: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ConfirmButton = styled.button`
  ${ButtonBase}

  color: ${Colors.white};
  box-shadow: 0px 15px 30px 0px #d4005026;
  background-color: ${Colors.primary100};

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
`;

export const CancelButton = styled.button`
  ${ButtonBase}

  color: ${Colors.primary100};
  background-color: ${Colors.primary10};
`;

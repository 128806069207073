import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 18px;
  column-gap: 20px;
  border-radius: 8px;

  background: ${Colors.secondary10};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  > b {
    font-family: "Poppins";
    color: ${Colors.secondary70};
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
  }

  > span {
    font-family: "Poppins";
    color: ${Colors.secondary60};
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.8rem;
  }
`;

export const EndAdornmentContainer = styled.div`
  margin-left: auto;
`;

export const ItemsSelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
